<template>
	<v-sheet class="asset" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<template v-if="warranty.isActive">
										<v-chip color="green" text-color="white" class="mr-2" label> Active </v-chip>
									</template>
									<template v-if="warranty.status_details">
										<v-chip
											:color="warranty.status_details ? warranty.status_details.color : ''"
											:text-color="warranty.status_details ? warranty.status_details.textcolor : ''"
											label
										>
											{{ warranty.status_details ? warranty.status_details.text : "" }}
										</v-chip>
									</template>
								</span>
							</p>
						</v-flex>
						<v-flex :class="`text-right ${deviceWidth > 1360.02 ? 'md6' : 'md4'}`">
							<template>
								<template v-if="deviceWidth > 1360.02">
									<template v-if="warranty && warranty.status == 1">
										<v-btn
											v-if="getPermission('checkout:create')"
											v-on:click="doAction('edit')"
											color="cyan darken-2 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-pencil</v-icon> Edit
										</v-btn>
										<v-btn
											v-if="getPermission('checkout:create')"
											v-on:click="doAction('delete')"
											color="red darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-delete</v-icon> Delete
										</v-btn>
									</template>
									<template
										v-if="
											warranty && warranty.is_extended != 1 && (warranty.status == 2 || warranty.status == 4)
										"
									>
										<v-btn
											v-if="getPermission('checkout:create')"
											v-on:click="doAction('extend')"
											color="blue darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-outline</v-icon> Extend
										</v-btn>
									</template>
									<template v-if="warranty && (warranty.status == 2 || warranty.status == 4)">
										<v-btn
											v-if="getPermission('checkout:create')"
											v-on:click="doAction('terminate')"
											color="red darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-block-helper</v-icon> Terminate
										</v-btn>
									</template>
								</template>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="warranty.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div
								v-if="
									warranty && warranty.asset && warranty.asset.pico_barcode && warranty.asset.barcode_image
								"
								style="max-width: 300px"
							>
								<div class="d-inline-block text-center">
									<img
										style="max-width: 100%; height: 30px"
										:src="warranty.asset && warranty.asset.barcode_image"
										:alt="warranty.asset && warranty.asset.pico_barcode"
										class="cursor-pointer"
										v-on:click="barcodeDialog = true"
									/>
									<pre class="mb-0 barcode-text bold-700">{{ warranty && warranty.barcode }}</pre>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600 mr-2">Warranty Cost : </span>
										<span class="fw-500">
											<span class="fw-500 no-wrap">
												<ShowValue
													:object="warranty"
													object-key="warranty_cost_formatted"
													label="supplier"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600"> Updated On : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="warranty" object-key="updated_at_formatted" label="created on">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600"> Created On : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="warranty" object-key="added_at_formatted" label="created on">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">
											<template v-if="warranty.status == 1">Active After :</template>
											<template v-else-if="warranty.status == 2 || warranty.status == 4"
												>Expired After :</template
											>
											<template v-else-if="warranty.status == 3">Expired Before :</template>
										</span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="warranty" object-key="cost_price_formatted" label="data">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Updated By : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="warranty" object-key="updated_by.display_name" label="updated by">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Created By : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="warranty" object-key="added_by.display_name" label="created by">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="warrantyTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon>

						Details
					</v-tab>
					<v-tab href="#event" class="pr-8">
						<v-icon small left>mdi-history</v-icon>
						<v-badge color="cyan" :content="WarrantyLog"> Logs </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('asset:update')" href="#file" class="pr-8">
						<v-icon small left>mdi-file-document-multiple</v-icon>
						<v-badge color="cyan" :content="WarrantyFile"> Files </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('asset:update')" href="#comment" class="pr-8">
						<v-icon small left>mdi-comment-multiple</v-icon>
						<v-badge color="cyan" :content="Warrantycomment"> Comments </v-badge>
					</v-tab>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="warrantyTab">
					<v-tab-item value="detail">
						<div class="mx-4 mt-3">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<div class="w-100 d-flex">
									<div class="w-50 mr-2">
										<div class="light-green-bg">
											<v-layout
												class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
												style="background-color: #c9e391"
											>
												<v-flex class="font-level-3-bold my-auto">
													<v-icon small left>mdi-format-list-bulleted-square</v-icon>
													Warranty Details
												</v-flex>
											</v-layout>
											<div :class="checkWExpDate(warranty.end_date) ? 'expired_details' : ''">
												<table width="100%" class="detail-table scattered-table ml-2">
													<tr>
														<th width="250">Warranty Start Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="warranty_start_date_formatted"
																label="warranty Start Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250">Warranty End Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="warranty_end_date_formatted"
																label="warranty End Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250px">Warranty Reminder Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="warranty_reminder_date_formatted"
																label="warranty Reminder Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250px">Warranty Reminder End Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="warranty_reminder_endDate_formatted"
																label="Warranty Reminder End Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250px">Warranty Cost</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="warranty_cost_formatted"
																label="Warranty Cost"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250">Remark</th>
														<td width="8">:</td>
														<td>
															<ShowValue :object="warranty" object-key="remark" label="remark"> </ShowValue>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
									<div class="w-50 ml-2">
										<div class="light-gradient-blue-bg">
											<v-layout
												class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
												style="background-color: #a6cdf3"
											>
												<v-flex class="font-level-3-bold my-auto">
													<v-icon small left>mdi-format-list-bulleted-square</v-icon>
													<span class="text-capitalize">{{ warranty && warranty.type }}</span> Details
												</v-flex>
											</v-layout>
											<table width="100%" class="detail-table scattered-table ml-2">
												<tr>
													<th width="250">
														<span class="text-capitalize">{{ warranty && warranty.type }}</span> #
													</th>
													<td width="8">:</td>
													<td>
														<ShowValue :object="parentDetails" object-key="barcode" label="Asset Id"></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Category</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="group_relation.name"
															label="category"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Purchased On</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="purchased_on_formatted"
															label="purchased on"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Cost Price</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="cost_price_formatted"
															label="cost price"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Contact for Item</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="contact"
															label="Contact for Item"
														></ShowValue>
													</td>
												</tr>
												<tr v-if="warranty && warranty.type == 'asset'">
													<th width="250">Is Return After End Life</th>
													<td width="8">:</td>
													<td>
														{{ parentDetails && parentDetails.is_return == 1 ? "Yes" : "No" }}
													</td>
												</tr>
												<tr>
													<th width="250">Created On</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="added_at_formatted"
															tooltip
															tooltip-value="created on"
															label="created on"
														></ShowValue>
													</td>
												</tr>
												<tr v-if="warranty && warranty.type == 'asset'">
													<th width="250">Remark</th>
													<td width="8">:</td>
													<td>
														<ShowValue :object="parentDetails" object-key="remark" label="remark"> </ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Description</th>
													<td width="8">:</td>
													<td>
														<ShowValue :object="parentDetails" object-key="description" label="description">
														</ShowValue>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
								<div class="w-100 d-flex">
									<div class="w-50 mr-2">
										<div class="light-red-bg" v-if="warranty && warranty.status == 5">
											<v-layout
												class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
												style="background-color: #89375f5e"
											>
												<v-flex class="font-level-3-bold my-auto">
													<v-icon small left>mdi-format-list-bulleted-square</v-icon>
													Warranty Terminate Details
												</v-flex>
											</v-layout>
											<div :class="checkWExpDate(warranty.end_date) ? 'expired_details' : ''">
												<table width="100%" class="detail-table scattered-table ml-2">
													<tr>
														<th width="250px">Terminate Date</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="terminate_date_formatted"
																label="Terminate Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th width="250px">Terminate Reason</th>
														<td width="8">:</td>
														<td>
															<ShowValue
																:object="warranty"
																object-key="terminate_reason"
																label="Terminate Reason"
															></ShowValue>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
									<div class="w-50 ml-2">
										<div class="light-orange-bg" v-if="warranty && warranty.type == 'subpart'">
											<v-layout
												class="p-4 border-bottom-dark-blue min-height-35px pt-0 pb-0"
												style="background-color: #ffb760"
											>
												<v-flex class="font-level-3-bold my-auto">
													<v-icon small left>mdi-format-list-bulleted-square</v-icon>
													Subpart Asset Details
												</v-flex>
											</v-layout>
											<table width="100%" class="detail-table scattered-table ml-2">
												<tr>
													<th width="250">Asset #</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="asset.barcode"
															label="Asset Id"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Category</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="asset.group_relation.name"
															label="category"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Purchased On</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="asset.purchased_on_formatted"
															label="purchased on"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Cost Price</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="asset.cost_price_formatted"
															label="cost price"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Created On</th>
													<td width="8">:</td>
													<td>
														<ShowValue
															:object="parentDetails"
															object-key="asset.added_at_formatted"
															tooltip
															tooltip-value="created on"
															label="created on"
														></ShowValue>
													</td>
												</tr>
												<tr>
													<th width="250">Description</th>
													<td width="8">:</td>
													<td>
														<ShowValue :object="parentDetails" object-key="asset.description" label="description">
														</ShowValue>
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="warrantyTab == 'event'"
							class="mx-4"
							type-text="Warranty"
							type="warranty"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="warrantyTab == 'file'"
							class="mx-4"
							type-text="Warranty Files"
							type="warranty"
							:type-uuid="uuid"
							:type-id="warrantyId"
							:is_main="true"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							v-if="warrantyTab == 'comment'"
							class="mx-4"
							type-text="Warranty"
							type="warranty"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support">
						<SupportTemplate
							v-if="warrantyTab == 'support'"
							class="mx-4"
							type-text="Warranty"
							type="warranty"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Warranty"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
			>
			</DeleteTemplate>
			<TerminateTemplate
				type="Warranty"
				:barcode="warranty.barcode"
				:uuid="uuid"
				v-on:success="
					() => {
						terminateDialog = false;
						getWarranty();
					}
				"
				v-on:close="terminateDialog = false"
				:terminate-dialog="terminateDialog"
			>
			</TerminateTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ImageTemplate from "@/view/components/Image";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import TerminateTemplate from "@/view/components/TerminateDialog";
import { GetWarranty } from "@/core/lib/warranty.lib";
import SupportTemplate from "@/view/components/SupportTemplate";
import { EventBus } from "@/core/event-bus/event.bus";
import MomentJS from "moment-timezone";
import EventListing from "@/view/components/EventListing";

export default {
	name: "warranty-detail",
	title: "Detail Warranty",
	data() {
		return {
			extendCheckoutDialog: false,
			checkinDialog: false,
			serviceDialog: false,
			reservationDialog: false,
			serviceScheduleDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			checkoutDialog: false,
			retireDialog: false,
			deleteDialog: false,
			terminateDialog: false,
			deleteURL: null,
			deleteText: null,
			warrantyTab: null,
			uuid: null,
			warrantyId: 0,
			contentLoaded: false,
			pageLoading: true,
			warranty: {},
			deviceWidth: document.documentElement.clientWidth,
			Warrantycomment: "0",
			WarrantyLog: "0",
			WarrantyFile: "0",
			parentDetails: {},
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		checkWExpDate(date) {
			let currentData = MomentJS();
			let enddate = MomentJS(date);
			if (enddate.diff(currentData, "days") > 0) {
				return false;
			}
			return true;
		},
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "update-warranty",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "terminate":
					this.terminateDialog = true;
					break;
				case "extend":
					this.$router.push({
						name: "extendWarranty",
						query: { t: new Date().getTime(), extendId: this.uuid },
					});
					break;
			}
		},
		getWarranty() {
			GetWarranty(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.warranty = data;
					if (data && data.type == "asset") {
						this.parentDetails = data.asset;
					} else if (data && data.type == "subpart") {
						this.parentDetails = data.subpart;
						this.parentDetails.group_relation =
							data.subpart && data.subpart.category_relation ? data.subpart.category_relation : {};
						this.parentDetails.purchased_on_formatted = data.subpart
							? data.subpart.purchased_date_formatted
							: "";
					}
					this.warrantyId = data.id;
					this.deleteText = `#${data.barcode}`;
					this.deleteURL = `warranty/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Warranty", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
					this.WarrantyFile = data.WarrantyFile.toString();
					(this.Warrantycomment = data.Warrantycomment.toString()),
						(this.WarrantyLog = data.WarrantyLog.toString());
				})
				.catch((error) => {
					//this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		ShowValue,
		Comments,
		EventListing,
		DeleteTemplate,
		SupportTemplate,
		ImageTemplate,
		TerminateTemplate,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Warranty", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getWarranty();

		EventBus.$on("reload:warranty", () => {
			this.getWarranty();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:warranty");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
