<template>
	<Dialog :dialog.sync="documentDialog">
		<template v-slot:title> Upload Document </template>
		<template v-slot:body>
			<v-form
				ref="documentForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="submitDocument()"
			>
				<!-- <div class="px-2">
					<AutoCompleteInput
						hide-details
						:items="customerList"
						:disabled="attachLoading"
						:loading="attachLoading"
						id="group"
						:rules="[vrules.required(customer, 'Customer')]"
						:class="{ required: !customer }"
						placeholder="Customer"
						v-model="customer"
					></AutoCompleteInput>
				</div>
				<br /> -->
				<table style="table-layout: fixed" class="table-document" width="100%">
					<template v-for="(file, index) in files">
						<tr :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
							<td width="45%" class="px-2">
								<v-file-input
									:id="`document-file-${index}`"
									placeholder="Select File"
									outlined
									class="mt-3"
									prepend-icon=""
									prepend-inner-icon="mdi-attachment"
									hide-details
									v-model="file.file"
									v-on:change="updateFile(index, $event)"
									:rules="[vrules.requiredFile(file.file, `File ${index + 1}`)]"
									:class="{ required: !file.file }"
									v-on:click:clear="updateFile(index, $event)"
								></v-file-input>
							</td>
							<td width="45%" class="px-2">
								<TextInput
									:id="`document-name-${index}`"
									:class="{ required: !file.name }"
									:rules="[vrules.required(file.name, `Name ${index + 1}`)]"
									v-model="file.name"
									hide-details
									placeholder="File Name"
									:suffix="file.suffix"
								></TextInput>
							</td>
							<td width="10%" align="center" valign="middle" class="px-2" :rowspan="extensive ? 4 : 2">
								<v-btn
									:disabled="files.length < 2"
									v-on:click="removeFile(index)"
									color="red lighten-1 white--text"
									class="mt-3"
									icon
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</td>
						</tr>
						<tr
							v-if="extensive"
							:key="`file-date-${index}`"
							:class="{ 'table-alternate-row': index % 2 }"
						>
							<td width="45%" class="px-2">
								<DatePicker
									placeholder="Start Date"
									id="export-from-date"
									v-model="file.start_date"
									:min-date="currentDate"
								></DatePicker>
							</td>
							<td width="45%" class="px-2">
								<DatePicker
									placeholder="End Date"
									id="export-from-date"
									v-model="file.end_date"
									:min-date="file.start_date"
								></DatePicker>
							</td>
						</tr>
						<tr
							v-if="extensive"
							:key="`file-reminder-date-${index}`"
							:class="{ 'table-alternate-row': index % 2 }"
						>
							<td width="90%" colspan="2" class="px-2">
								<DatePicker
									placeholder="Reminder Date"
									id="export-from-date"
									v-model="file.reminder_date"
								></DatePicker>
							</td>
						</tr>
						<tr v-if="extensive" :key="`file-tag-${index}`" :class="{ 'table-alternate-row': index % 2 }">
							<td width="90%" colspan="2" class="px-2 pt-2">
								<v-combobox
									id="tags"
									hide-details
									v-model="file.tags"
									class="combobox unsetMaxHeight"
									:filter="filterTag"
									:hide-no-data="!search"
									outlined
									:items="items"
									:search-input.sync="file.search"
									item-text="tag"
									item-value="id"
									hide-selected
									multiple
									small-chips
									dense
									placeholder="Search Tag"
									v-on:keyup="(e) => checkTag(e, index)"
								>
									<template v-slot:no-data>
										<v-chip label class="my-2 mx-2" small>
											{{ file.search }}
										</v-chip>
									</template>
									<template v-slot:selection="{ attrs, item, parent, selected }">
										<v-chip
											class="my-1"
											v-if="item === Object(item)"
											v-bind="attrs"
											:input-value="selected"
											label
											small
										>
											<span class="pr-2">
												{{ item.tag }}
											</span>
											<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
										</v-chip>
									</template>
									<v-list-item class="my-2 white" v-slot:item="{ item }">
										<v-list-item-content>
											<v-list-item-title>
												<v-chip class="mb-1" label small>
													{{ item.tag }}
												</v-chip>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-combobox>
							</td>
						</tr>
						<tr :key="`file-remark-${index}`" :class="{ 'table-alternate-row': index % 2 }">
							<td colspan="2" width="90%" class="px-2 py-3">
								<TextAreaInput
									:rows="2"
									dense
									:id="`document-remark-${index}`"
									hide-details
									v-model="file.remark"
									placeholder="Enter description..."
									:disabled="attachLoading"
									:loading="attachLoading"
									v-on:keypress="(e) => manageLimit(e, index)"
								></TextAreaInput>
							</td>
						</tr>
					</template>
				</table>
				<v-btn color="blue darken-4 white--text" class="mt-2" tile depressed v-on:click="addMore()">
					<v-icon>mdi-plus</v-icon> Add More...
				</v-btn>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="!export_valid || attachLoading"
				:loading="attachLoading"
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				v-on:click="submitDocument()"
			>
				Submit
			</v-btn>
			<v-btn
				depressed
				tile
				:disabled="attachLoading"
				:loading="attachLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog.vue";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
import MomentJS from "moment-timezone";

export default {
	name: "export-dialog",
	data() {
		return {
			export_valid: true,
			attachLoading: false,
			files: [
				{
					file: null,
					name: null,
					remark: null,
					suffix: null,
					tags: null,
					search: null,
				},
			],
			customerList: [],
			customer: null,
			currentDate: MomentJS().format("YYYY-MM-DD"),
			search: null,
			items: [],
		};
	},
	props: {
		documentDialog: {
			type: Boolean,
			default: false,
		},
		documentType: {
			type: String,
			default: null,
		},
		documentId: {
			type: Number,
			default: null,
		},
		parentType: {
			type: String,
			default: null,
		},
		parentTypeId: {
			type: Number,
			default: null,
		},
		extensive: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		documentDialog(param) {
			if (param) {
				this.customer = null;
				this.files = [
					{
						file: null,
						name: null,
						remark: null,
						suffix: null,
						start_date: null,
						end_date: null,
						reminder_date: null,
					},
				];
			}
		},
		/* "asset.*.tags": {
			deep: true,
			handler(val, prev) {
				if (val.length === prev.length) return;

				this.asset.tags = val.map((v) => {
					if (typeof v === "string") {
						v = {
							tag: v,
						};
						this.items.push(v);
					}

					return v;
				});
			},
		}, */
	},
	methods: {
		checkTag(e, index) {
			if (e.key == "Enter") {
				if (this.files[index].tags) {
					this.files[index].tags = this.files[index].tags.map((v) => {
						if (typeof v === "string") {
							v = {
								tag: v,
							};
							this.items.push(v);
						}

						return v;
					});
				}
			}
		},
		manageLimit(e, index) {
			if (this.files[index].remark && this.files[index].remark.length > 199) {
				e.preventDefault();
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
				remark: null,
				suffix: null,
			});
		},
		submitDocument() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.documentForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.documentForm.validate()) {
				return false;
			}

			const params = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				params.append(`file[${i}][file]`, this.files[i].file);
				params.append(`file[${i}][name]`, this.files[i].name);
				if (this.files[i].remark) {
					params.append(`file[${i}][remark]`, this.files[i].remark);
				}
				if (this.files[i].start_date && this.files[i].end_date) {
					params.append(`file[${i}][start_date]`, this.files[i].start_date);
					params.append(`file[${i}][end_date]`, this.files[i].end_date);
				}
				if (this.files[i].reminder_date) {
					params.append(`file[${i}][reminder_date]`, this.files[i].reminder_date);
				}
				if (this.files[i].tags && this.files[i].tags.length > 0) {
					for (let t = 0; t < this.files[i].tags.length; t++) {
						if (this.files[i].tags[t].tag) {
							params.append(`file[${i}][tags][${t}]`, this.files[i].tags[t].tag);
						}
					}
				}
			}

			params.append("type_id", this.documentId);
			params.append("type", this.documentType);
			if (this.parentType && this.parentTypeId && this.parentTypeId > 0) {
				params.append("parent_type", this.parentType);
				params.append("parent_type_id", this.parentTypeId);
			}

			this.attachLoading = true;

			ApiService.setHeader();
			ApiService.upload("all-documents", params)
				.then(() => {
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		getTags() {
			const _this = this;
			ApiService.setHeader();
			ApiService.get("all-tags").then((res) => {
				_this.items = res.data;
			});
		},
		filterTag(item, queryText, itemText) {
			if (item.header) return false;
			const hasValue = (val) => (val != null ? val : "");

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
		},
	},
	components: {
		Dialog,
		TextInput,
		//AutoCompleteInput,
		TextAreaInput,
		DatePicker,
	},
	mounted() {
		this.customerList = this.localDB("customers", []);
		this.getTags();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
};
</script>
