import ApiService from "@/core/services/api.service";

export const GetWarranty = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`warranty/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ExtendedWarranty = (params, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`warranty/${uuid}/extend`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateWarranty = (params, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`warranty-update/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const TerminateWarranty = (params, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`warranty-terminate/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
