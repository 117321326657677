<template>
	<Dialog :dialog="terminateDialog" :dialog-width="950">
		<template v-slot:title> Terminate Warranty - {{ barcode }}</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-form
					ref="warrantyForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="terminateWarranty"
				>
					<v-row>
						<v-col md="3" class="my-auto py-0">
							<label for="vendor" class="btx-label mt-2 required">Termination Date</label>
						</v-col>
						<v-col md="9" class="py-0">
							<DatePicker
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="warranty-end-date"
								placeholder="End Date"
								:min-date="currentDate"
								v-model="terminate_date"
								:rules="[vrules.required(terminate_date, 'Terminate Date')]"
								:class="{
									required: !terminate_date,
								}"
							></DatePicker>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="vendor" class="btx-label mt-2 required">Reason</label>
						</v-col>
						<v-col md="9" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="Reason"
								placeholder="Reason"
								v-model="terminate_reason"
								:rules="[vrules.required(terminate_reason, 'Terminate Reason')]"
								:class="{
									required: !terminate_reason,
								}"
							></TextAreaInput>
						</v-col>
						<v-col md="3" class="my-auto py-0">
							<label for="attachment" class="btx-label mt-2">Attachment</label>
						</v-col>
						<v-col md="9" class="py-0">
							<template v-for="(data, index) in wtx_attachments">
								<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
									<v-col md="5" class="py-0">
										<v-file-input
											v-model="data.file"
											placeholder="Click here to select files"
											color="blue darken-4"
											multiple
											outlined
											class="mt-3"
											prepend-icon=""
											prepend-inner-icon="mdi-attachment"
											hide-details
											v-on:change="limitAttachmentWarranty($event, index)"
										>
										</v-file-input>
									</v-col>
									<v-col md="5" class="py-0">
										<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
									</v-col>
									<v-col md="1" class="py-0">
										<v-btn
											:disabled="wtx_attachments.length < 2"
											v-on:click="removeFileWarranty(index)"
											class="white--text mt-3"
											depressed
											color="red darken-4"
											tile
											style="margin-left: -10px"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</v-col>
									<v-col md="1" class="py-0">
										<v-btn
											:disabled="wtx_attachments.length > 2"
											v-on:click="addMoreWarranty()"
											class="white--text mt-3"
											depressed
											color="blue darken-4"
											tile
											style="margin-left: -13px"
										>
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</template>
						</v-col>
					</v-row>
				</v-form>
			</v-row>
		</template>
		<template v-slot:action>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="pageLoading"
				depressed
				color="red lighten-1"
				tile
				v-on:click="terminateWarranty()"
			>
				Yes! Terminate
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog"> No, Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
//import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { TerminateWarranty } from "@/core/lib/warranty.lib";
import TextAreaInput from "@/view/components/TextAreaInput";
import MomentJS from "moment-timezone";
import DatePicker from "@/view/components/DatePicker";
import { UploadFiles } from "@/core/lib/upload.lib";
import ObjectPath from "object-path";
import TextInput from "@/view/components/TextInput";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		uuid: {
			type: String,
			default: null,
		},
		barcode: {
			type: String,
			default: null,
		},
		terminateDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			terminate_reason: null,
			terminate_date: MomentJS().format("YYYY-MM-DD"),
			currentDate: MomentJS().format("YYYY-MM-DD"),
			wtx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
		};
	},
	components: {
		Dialog,
		TextAreaInput,
		TextInput,
		DatePicker,
	},
	methods: {
		addMoreWarranty() {
			this.wtx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileWarranty(index) {
			this.wtx_attachments.splice(index, 1);
			if (this.wtx_attachments.length < 1) {
				this.addMoreWarranty();
			}
		},
		limitAttachmentWarranty(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.wtx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.wtx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeDialog() {
			this.terminate_date = MomentJS().format("YYYY-MM-DD");
			this.terminate_reason = null;
			this.$emit("close", true);
		},
		terminateWarranty() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.warrantyForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.warrantyForm.validate()) {
				return false;
			}
			let formData = {
				terminate_reason: _this.terminate_reason,
				terminate_date: _this.terminate_date,
				attachment: this.wtx_attachments,
			};
			TerminateWarranty(formData, _this.uuid)
				.then(() => {
					_this.terminate_reason = null;
					_this.terminate_date = MomentJS().format("YYYY-MM-DD");
					this.$emit("success");
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! Warranty has been terminated.` },
					]);
				})
				.catch((error) => {
					//_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
					_this.contentLoaded = true;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
