<template>
	<Dialog :dialog="deleteDialog" :dialog-width="600">
		<template v-slot:title> Delete {{ type }}</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<span class="svg-icon svg-icon-lg delete-confirm-icon">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
						<!--end::Svg Icon-->
					</span>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						Deleting <span class="text-capitalize">{{ type }}</span>
						<span class="red--text lighten-1 font-level-1-bold"> {{ deleteText }}</span> is irreversible
						and will also delete all associated records.<br />Are you sure about deleting it?
					</p>
				</v-col>
				<v-col v-if="deleteNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ deleteNote }}</em>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="deleteButton"
				:disabled="deleteButton"
				depressed
				color="red lighten-1"
				tile
				v-on:click="deleteRow()"
			>
				Yes! Delete
			</v-btn>
			<v-btn depressed tile :disabled="deleteButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		deleteUrl: {
			type: String,
			default: null,
		},
		deleteText: {
			type: String,
			default: null,
		},
		deleteNote: {
			type: String,
			default: null,
		},
		deleteDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			deleteButton: false,
		};
	},
	components: {
		Dialog,
	},
	methods: {
		deleteRow() {
			this.deleteButton = true;

			ApiService.delete(this.deleteUrl)
				.then(() => {
					this.$emit("success");
					this.$store.dispatch(SET_LOCAL_DB);
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: `Success ! ${this.type} has been deleted.` },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.deleteButton = false;
				});
		},
	},
};
</script>
